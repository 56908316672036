<template>
  <div>
    <h3>
      {{ title }}
    </h3>
    <div class="h5 text-muted">
      {{ description }}
    </div>
    <hr />
    <div class="card" v-if="!loading">
      <div class="card-header">
        <div class="card-title mb-0">User</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="userAddForm">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.userAddForm"
          />
          <form @submit.prevent="onSubmit">
            <div class="row mb-2">
              <div class="col-12">
                <ValidationProvider
                  name="Admin User Role"
                  rules="required"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="userRole">
                    Admin User Role<span class="error"> *</span>
                  </label>
                  <b-form-radio-group
                    id="userRole"
                    v-model="parentUser.userRole"
                    :options="userRoles"
                    name="userRole"
                    v-bind="ariaInput"
                    class="mb-2"
                  ></b-form-radio-group>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Select role to be assigned to user</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="First Name"
                  id="firstName"
                  placeholder="Enter user first name"
                  v-model="parentUser.firstName"
                  rules="required|customerName|max:30"
                  class="mb-2"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Last Name"
                  id="lastName"
                  placeholder="Enter user last name"
                  v-model="parentUser.lastName"
                  rules="required|customerName|max:30"
                  class="mb-2"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Email"
                  id="email"
                  placeholder="Enter user email"
                  v-model="parentUser.email"
                  :rules="`${!!parentUser.id ? '' : 'required|email|max:256'}`"
                  class="mb-2"
                  :disabled="!!parentUser.id"
                />
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Username"
                  id="username"
                  placeholder="Enter desired username"
                  v-model="parentUser.username"
                  :rules="
                    `${!!parentUser.id ? '' : 'required|min:8|username|max:20'}`
                  "
                  class="mb-2"
                  :disabled="!!parentUser.id"
                />
              </div>
            </div>
            <hr />
            <div class="row mb-2">
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Regions"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="regions">
                    Regions
                  </label>
                  <multiselect
                    v-model="selectedRegions"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="regions"
                    ref="regions"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Region(s)"
                    :options="regions"
                    v-bind="ariaInput"
                    @select="filterParksAdd"
                    @remove="filterParksRemove"
                    :disabled="roleSelected"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a region will select all parks associated with that
                  region. Removing a region will remove all parks of that region
                  from the list of selected parks.
                </small>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="selectAllParksAndRegions"
                  :disabled="roleSelected"
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="deselectAllParksAndRegions"
                  :disabled="roleSelected"
                >
                  DESELECT ALL
                </button>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <label for="filterInput"
                      >{{ parks.filter(x => x.isSelected).length }} of
                      {{ parks.length }} total parks selected</label
                    >
                    <div class="d-flex">
                      <div class="grow">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          id="filterInput"
                          placeholder="Search Parks..."
                          class="mb-3"
                          :disabled="roleSelected"
                        ></b-form-input>
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="filter = ''"
                          class="btn btn-primary"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12"
                    style="max-height: 300px; overflow-y: hidden"
                  >
                    <b-table
                      v-show="!roleSelected"
                      ref="table"
                      id="parkTable"
                      striped
                      :fields="fields"
                      :items="parks"
                      :filter="filter"
                      small
                      outlined
                      empty-text="No Park Results..."
                      empty-filtered-text="No Park Results..."
                      :filter-function="filterParks"
                      sticky-header="300px"
                      no-border-collapse
                      selectable
                      show-empty
                      :busy="loadingParks"
                      primary-key="id"
                      @filtered="onFilter"
                      @sort-changed="onSort"
                      no-select-on-click
                      :sort-null-last="true"
                      bordered
                      sort-icon-left
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <span
                            class="spinner-border spinner-border-sm mx-auto"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </template>
                      <template v-slot:cell(isSelected)="data">
                        <template
                          v-if="
                            data.rowSelected &&
                              checkLocationPermission(
                                'UserManagementUserManagement',
                                data.item.id
                              )
                          "
                        >
                          <i
                            @click="deselectPark(data.item.id)"
                            class="align-middle fa-fw fa-check-circle fas text-primary ml-2"
                          ></i>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <i
                            @click="selectPark(data.item.id)"
                            class="align-middle fa-fw fa-check-circle far ml-2"
                          ></i>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <button
                  type="submit"
                  class="btn btn-primary mr-2 my-1"
                  :disabled="parentLoading"
                >
                  <span
                    v-if="parentLoading"
                    class="spinner-border spinner-border-sm mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-else>{{ !parentUser.id ? "INVITE" : "SAVE" }}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mr-2 my-1"
                  @click="resetAllFields"
                  v-show="!parentUser.id"
                >
                  CLEAR
                </button>
                <button
                  type="button"
                  class="btn btn-cancel mr-2 my-1"
                  @click="goToUserSearch"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import Multiselect from "vue-multiselect";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminUserService from "@/services/admin/AdminUserService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminUserForm",
  mixins: [checkPermissionMixin],
  props: {
    title: String,
    description: String,
    user: Object,
    parentLoading: Boolean
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    TextInput,
    Multiselect
  },
  data() {
    return {
      loading: false,
      parentUser: {},
      errors: [],
      userRoles: [],
      loadingParks: false,
      parks: [],
      excludedParks: [],
      regions: [],
      previouslySelectedRegions: [],
      selectedRegions: [],
      fields: [
        { key: "isSelected", label: "Selected", sortable: true },
        { key: "name", sortable: true }
      ],
      filter: ""
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedParkIds() {
      return this.parks.filter(x => x.isSelected === true).map(y => y.id);
    },
    userRoleId() {
      return this.$store.getters["auth/userRoleId"];
    },
    roleSelected() {
      return !this.parentUser.userRole || this.parentUser.userRole === 2;
    },
    userLocations() {
      return this.$store.getters["auth/userLocations"];
    }
  },
  methods: {
    onSubmit() {
      this.$emit("loading", true);
      this.$refs.userAddForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.userAddForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.userAddForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          this.$emit("loading", false);
        } else {
          this.errors = [];
          if (
            this.selectedParkIds.length !== 0 ||
            // agency admin doesn't need parks
            this.parentUser.userRole == 2
          ) {
            let userRequest = {
              id: this.parentUser.id,
              userRoleId: this.parentUser.userRole,
              firstName: this.parentUser.firstName,
              lastName: this.parentUser.lastName,
              email: this.parentUser.email,
              username: this.parentUser.username,
              userLocationIds: [...this.selectedParkIds, ...this.excludedParks]
            };
            this.$emit("submit", userRequest);
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "You must select at least one park...",
              layer: "admin"
            });
            this.$emit("loading", false);
          }
        }
      });
    },
    resetAllFields() {
      this.parentUser = {};
      this.selectedRegions = [];
      this.filter = "";
      this.parks.map(x => {
        return {
          ...x,
          isSelected:
            x.isSelected === true &&
            this.checkLocationPermission("UserManagementUserManagement", x.id)
              ? false
              : x.isSelected
        };
      });
    },
    goToUserSearch() {
      this.resetAllFields();
      this.$router.push("/admin/user-search").catch(() => {});
    },
    selectPark(id) {
      this.parks = this.parks.map(x => {
        return { ...x, isSelected: x.id === id ? true : x.isSelected };
      });
      this.onSort();
    },
    deselectPark(id) {
      this.parks = this.parks.map(x => {
        return { ...x, isSelected: x.id === id ? false : x.isSelected };
      });
      this.onSort();
    },
    onFilter() {
      // set timeout here specifically so it doesn't run after every character entry on filter
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.parks
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 250);
    },
    onSort() {
      // same here for set timeout, have to give the table a sec to return the sorted items
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.parks
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 100);
    },
    filterParks(data, filter) {
      if (
        data.isSelected ||
        data.name.toLowerCase().includes(filter.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    async filterParksAdd(selectedRegion) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          !this.$refs.table.isRowSelected(index) &&
          x.regionId === selectedRegion.id &&
          this.checkLocationPermission("UserManagementUserManagement", x.id)
        ) {
          this.parks = this.parks.map(z => {
            return { ...z, isSelected: z.id === x.id ? true : z.isSelected };
          });
        }
      });
      this.onSort();
    },
    async filterParksRemove(removedRegion) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          this.$refs.table.isRowSelected(index) &&
          x.regionId === removedRegion.id &&
          this.checkLocationPermission("UserManagementUserManagement", x.id)
        ) {
          this.parks = this.parks.map(z => {
            return { ...z, isSelected: z.id === x.id ? false : z.isSelected };
          });
        }
      });
      if (this.selectedRegions.length === 0) {
        this.parks = this.parks.map(m => {
          return {
            ...m,
            isSelected:
              m.isSelected === true &&
              this.checkLocationPermission("UserManagementUserManagement", m.id)
                ? false
                : m.isSelected
          };
        });
      }
      this.onSort();
    },
    async selectAllParksAndRegions() {
      this.parks = this.parks.map(x => {
        return {
          ...x,
          isSelected:
            x.isSelected === false &&
            this.checkLocationPermission("UserManagementUserManagement", x.id)
              ? true
              : x.isSelected
        };
      });
      this.selectedRegions = [...this.regions];
      this.onSort();
    },
    deselectAllParksAndRegions() {
      this.parks = this.parks.map(x => {
        return {
          ...x,
          isSelected:
            x.isSelected === true &&
            this.checkLocationPermission("UserManagementUserManagement", x.id)
              ? false
              : x.isSelected
        };
      });
      this.selectedRegions = [];
      this.onSort();
    },
    async getParks() {
      const lookupService = new AdminLookupService(this.tenantId);
      return await lookupService.getLocations();
    },
    async getRegions() {
      const lookupService = new AdminLookupService(this.tenantId);
      return await lookupService.getRegions();
    },
    async getUserRoles() {
      const userService = new AdminUserService(this.tenantId);
      return await userService.getUserRoles();
    },
    async initialize() {
      this.loading = true;
      const regions = this.getRegions();
      const roles = this.getUserRoles();
      const parks = this.getParks();
      await Promise.allSettled([regions, roles, parks])
        .then(responses => {
          this.regions = responses[0].value;
          this.userRoles = responses[1].value
            .filter(x => x.id != 1 && x.id >= +this.userRoleId)
            .map(y => {
              return { text: y.name, value: y.id };
            });
          this.parks = responses[2].value
            .filter(x =>
              this.checkLocationPermission("UserManagementUserManagement", x.id)
            )
            .map(y => {
              return {
                ...y,
                isSelected: this.parentUser?.userParks
                  ?.map(z => z.locationId)
                  ?.includes(y.id)
              };
            });
          this.excludedParks =
            this.userRoleId === 1 || this.userRoleId === 2
              ? []
              : responses[2].value
                  .filter(
                    x =>
                      this.parentUser?.userParks
                        ?.map(y => y.locationId)
                        ?.includes(x.id) && !this.userLocations.includes(x.id)
                  )
                  .map(n => n.id);
        })
        .finally(() => {
          this.onSort();
          this.loading = false;
        });
    }
  },
  watch: {
    user() {
      this.parentUser = JSON.parse(JSON.stringify(this.user));
    },
    "parentUser.userRole"() {
      if (this.parentUser.userRole === 2) {
        this.selectedRegions = [];
        this.parks = this.parks.map(x => {
          return { ...x, isSelected: false };
        });
      }
    }
  },
  async mounted() {
    this.parentUser = JSON.parse(JSON.stringify(this.user));
    await this.initialize();
    this.$refs.regions.$refs.search.setAttribute("autocomplete", "off");
  }
};
</script>

<style scoped>
.grow {
  flex-grow: 1;
}
</style>
